import './bootstrap';
import 'slick-carousel';
import 'jquery-parallax.js';
import 'waypoints/lib/jquery.waypoints.js';
import 'slicknav/jquery.slicknav.js';
import 'jquery.counterup';
import './components/comments/config';
import './components/comments/googleAnalytics';

$(document).ready(function() {
    'use strict';

    /* ------------------------------- Responsive Menu Start ------------------------------- */
    if ($('.main-navigation>ul').length != '') {
        $('.main-navigation>ul').slicknav();
    }
    /* ------------------------------- Responsive Menu Start ------------------------------- */

    /* ------------------------------- Testimonials Slider start ------------------------------- */
    if ($('.bu-testimonial-slider').length != '') {
        $('.bu-testimonial-slider').slick({
            infinite: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            dots: true
        });
    }
    /* ------------------------------- Testimonials Slider End --------------------------------- */

    /* ------------------------------- Counter start ------------------------------- */
    if ($('.counter').length != '') {
        $('.counter').counterUp({
            delay: 10,
            time: 1000
        });
    }
    /* ------------------------------- Counter End --------------------------------- */

    /* Sticky Header Function */
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 155) {
            $('#header').addClass('fixed-header');
        } else {
            $('#header').removeClass('fixed-header');
        }
    });
    /* Sticky Header Function */
});
