(function(window) {
    'use strict';

    const config = {
        trackingId: 'UA-69394831-1'
    };

    // Expose config
    window.pawnshop = window.pawnshop || {};
    window.pawnshop.config = config;
})(window);
