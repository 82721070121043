import 'autotrack';

if (window.pawnshop.environment === 'production') {
    (function(i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
            i[r] ||
            function() {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    })(
        window,
        document,
        'script',
        'https://www.google-analytics.com/analytics.js',
        'ga'
    );
} else {
    window.ga = function() {
        let mainArguments = Array.prototype.slice.call(arguments);
        mainArguments.unshift('[Google Analytics]');
        window.console.log.apply(window, mainArguments);
    };
}

window.ga('create', window.pawnshop.config.trackingId, 'auto');
window.ga('require', 'cleanUrlTracker');
window.ga('require', 'eventTracker');
window.ga('require', 'impressionTracker');
window.ga('require', 'maxScrollTracker');
window.ga('require', 'mediaQueryTracker');
window.ga('require', 'outboundFormTracker');
window.ga('require', 'outboundLinkTracker');
window.ga('require', 'pageVisibilityTracker');
window.ga('require', 'socialWidgetTracker');
window.ga('require', 'urlChangeTracker');
